<template>
  <div
    ref="slideSlide"
    class="home-slide"
    :data-slide="slide.i + 1"
    :id="slide.id.substring(2)"
    :class="slide.classes"
  >
    <div class="slide-background" ref="slideBackground">
      <div class="slide-background-image" ref="slideBackgroundImage">
        <img
          :src="slide.images.background"
          class="parallax-item"
          data-depth="0.15"
        />
      </div>
    </div>
    <div class="slide-background-mask">
      <div class="mask-left"></div>
      <div class="mask-top"></div>
      <div class="mask-right"></div>
    </div>
    <div class="slide-image-container container absolute center">
      <div class="slide-image" ref="slideImage">
        <img
          ref="slideMainImage"
          :src="slide.images.main"
          class="parallax-item"
          data-depth="0.4"
        />
      </div>
    </div>
    <div
      class="
        chapter-slide-content
        container
        layout-column layout-align-center-start
      "
      ref="slideContents"
    >
      <div
        class="slide-header layout-row parallax-item"
        ref="slideHeader"
        data-depth="0.05"
      >
        <div class="slide-chapter flex-noshrink">
          <div class="slide-chapter-box"></div>
        </div>
        <div class="slide-title flex-grow flex layout-align-start-center">
          <span class="slide-chapter-chapter trajan">Chapter</span>
          <span class="slide-chapter-no trajan">{{ slide.roman }}</span>
          <h1 ref="slideTitle">{{ slide.title }}</h1>
          <span
            class="slide-title-prefix h1"
            v-show="slide.titlePrefix !== ''"
            >{{ slide.titlePrefix }}</span
          >
        </div>
      </div>
      <div class="slide-intro layout-row parallax-item" data-depth="0.08">
        <div class="slide-left-spacer flex-noshrink"></div>
        <div class="slide-intro-p flex-grow">
          <p class="lead">{{ slide.content }}</p>
          <div class="lead-line"></div>
        </div>
      </div>
      <div class="slide-button layout-row parallax-item" data-depth=".11">
        <div class="slide-left-spacer flex-noshrink"></div>
        <div class="slide-btn flex-grow">
          <router-link
            v-if="!slide.external_chapter"
            class="btn btn-primary"
            :to="{
              name: 'view-chapter-section',
              params: { slide: slide.id, section: 1 },
            }"
          >
            <span>Enter Chapter</span>
            <i class="ai-right"></i>
          </router-link>
          <a
            v-else
            :href="slide.external_chapter_link"
            target="_blank"
            class="btn btn-primary"
          >
            <span>Enter Chapter</span>
            <i class="ai-right"></i>
          </a>
        </div>
      </div>
    </div>
    <div
      class="slide-navigation layout-column layout-align-center-start"
      ref="slideNav"
    >
      <a
        class="slide-next layout-align-center-center layout-row pointer"
        @click.prevent="$emit('changeslide', 'next')"
        :href="`/chapters/${slide.next.href}/`"
      >
        <span class="grey2 italic">Next</span>
        <i class="ai-right"></i>
        <span class="h4">{{ slide.next.title }}</span>
      </a>
      <a
        class="slide-prev layout-align-center-center layout-row pointer"
        @click.prevent="$emit('changeslide', 'prev')"
        :href="`/chapters/${slide.prev.href}/`"
      >
        <span class="grey2 italic">Prev</span>
        <i class="ai-left"></i>
        <span class="h4">{{ slide.prev.title }}</span>
      </a>
    </div>
    <div class="slide-scroll-indicator absolute" ref="slideScrollIndicator">
      <div v-if="!isDevice">
        <span class="scroll-btn">
          <span class="scroll-btn-dots">
            <span class="dots">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </span>
          <span class="mouse">
            <span></span>
          </span>
        </span>
      </div>
      <div class="swipe-icon" v-else></div>
    </div>
  </div>
</template>

<script>
import { chaptersSliderAnimations } from "@/mixins/chapters/slider.js";
import {
  modalFunctions,
  menuFunctions,
  parallaxFunctions,
} from "@/mixins/core.js";

export default {
  name: "ChapterSlide",
  metaInfo() {
    return {
      title: `Chapter ${this.slide.i + 1} - ${this.slide.title}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.slide.content,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${this.$urls.buildUrl}` + window.location.pathname,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `Chapter ${this.slide.i + 1} - ${this.slide.title}`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.slide.content,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `${this.$urls.img}/opengraph/${this.slide.i + 1}.jpg`,
        },
      ],
    };
  },
  mixins: [
    chaptersSliderAnimations,
    modalFunctions,
    menuFunctions,
    parallaxFunctions,
  ],
  components: {},
  props: {
    slide: {
      type: Object,
      required: true,
    },
    transitionSlideEnter: {},
    transitionSlideLeave: {},
  },
  data() {
    return {
      sliderParallax: [],
      parallaxOptions: {
        scalarX: device.type == "desktop" ? 3.5 : 4.5,
        scalarY: device.type == "desktop" ? 2.5 : 3,
        calibrateX: true,
        calibrateY: true,
        frictionX: 0.08,
        frictionY: 0.08,
      },
    };
  },
  computed: {
    currentSlide: function () {
      return this.slide.id;
    },
    isDevice() {
      if (device) {
        return device.type == "desktop" ? false : true;
      }
      return false;
    },
  },
  watch: {
    //watch for slide enter transition and get element
    transitionSlideEnter: function (el) {
      this.slideInAnimation(el);
    },
    transitionSlideLeave: function (el) {
      this.slideOutAnimation(el);
    },
    menuOpen(val) {
      this.pauseParallax(val);
    },
    modalOpen(val) {
      this.pauseParallax(val);
    },
  },
  methods: {
    enableParallax() {
      if (this.disableParallax) return;
      //background img
      this.sliderParallax.push(
        new this.$parallax(this.$refs.slideBackgroundImage, {
          ...this.parallaxOptions,
          limitY: 0,
          invertX: false,
        })
      );
      //foreground image
      this.sliderParallax.push(
        new this.$parallax(this.$refs.slideImage, {
          ...this.parallaxOptions,
          limitY: 20,
        })
      );
      //contents
      this.sliderParallax.push(
        new this.$parallax(this.$refs.slideContents, {
          ...this.parallaxOptions,
          //limitY: 0,
          invertX: true,
          selector: ".slide-header, .slide-intro, .slide-button",
        })
      );
    },
    destroyParallax() {
      if (this.disableParallax) return;

      this.sliderParallax.forEach(function (parallax) {
        parallax.destroy();
      });
      this.sliderParallax = [];
    },
    pauseParallax(val) {
      if (this.disableParallax) return;
      if (this.sliderParallax === undefined || this.sliderParallax == 0) return;

      if (val) {
        this.sliderParallax.forEach(function (parallax) {
          parallax.disable();
        });
      } else {
        this.sliderParallax.forEach(function (parallax) {
          parallax.enable();
        });
      }
    },
  },
  mounted() {},
  beforeDestroy() {
    //parallax clean up on router view swap
    //this.destroyParallax;
  },
};
</script>

<style scoped>
.home-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.home-slide::after {
  content: "";
  box-shadow: -4rem -4rem 7rem rgba(0, 0, 0, 0.15) inset;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}
.home-slide > * {
  opacity: 1;
}
.slide-background {
  position: absolute;
  width: 43%;
  height: 100%;
  right: 0;
  overflow: hidden;
  transform-origin: center right;
  width: 100%;
}
.items-shown .slide-background {
  width: 100% !important;
}
.slide-background-image {
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw !important;
  height: 100vh !important;
  position: absolute;
}
.slide-background img {
  width: 110%;
  height: 100%;
  object-fit: cover;
  object-position: bottom right;
  position: absolute;
  filter: url(#filter-chapter-slider);
}
.slide-background-mask {
  width: 100%;
  position: absolute;
  height: 100%;
  transform-origin: center right;
}
.slide-background-mask > div {
  background: white;
  position: absolute;
}
.slide-background-mask .mask-top {
  height: 92px;
  top: -42px;
  width: 100%;
  display: none;
}
.slide-background-mask .mask-right {
  right: 0;
  width: 38px;
  height: 100%;
  display: none;
}
.slide-background-mask .mask-left {
  height: 100%;
  left: 0;
  width: 57%;
  width: 70%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.95) 65%,
    rgba(255, 255, 255, 0) 100%
  );
}

.chapter-slide-content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex !important;
  pointer-events: auto !important;
  margin: 0 auto;
  font-size: 0.95rem;
}
.chapter-slide-content > * {
  display: flex !important;
  position: relative !important;
}
.slide-header {
  margin: 4em 20% 0.1em 0;
  width: 100%;
  display: flex !important;
  font-size: 0.85rem;
}
.slide-title {
  position: relative;
  margin-top: -0.353em;
  min-height: 18.2em;
  align-items: center;
}
.slide-title h1 {
  padding-left: 1.25rem;
  margin: 0;
  max-width: 5.921em;
  text-shadow: var(--title-shadow);
  font-size: 7.6em;
}
.slide-title-prefix {
  position: absolute;
  text-align: right;
  left: 0;
  top: 0;
  background: white;
  padding: 0.184em 0.043em 0.184em 0.184em;
  font-size: 3.4em;
  letter-spacing: 0.047em;
  transform: translate(-100%, 0);
  display: block;
  box-shadow: 0.15em 0 0 0 white;
}
.slide-chapter {
  position: relative;
  border-left: 5px solid var(--grey);
  box-sizing: content-box;
  margin-left: -5px;
}
.slide-chapter .slide-chapter-box {
  position: absolute;
  background: var(--grey);
  width: 100%;
  height: 100%;
}

.items-shown .slide-chapter .slide-chapter-box {
  width: 100% !important;
}
.slide-chapter .slide-chapter-box::before {
  content: "";
  background: var(--grey);
  height: 100%;
  position: absolute;
  left: 0;
  width: 100vw;
  transform: translate(-100%, 0);
}
.slide-chapter-chapter,
.slide-chapter-no {
  color: white;
  position: absolute;
  transform: translate(-100%, 0);
}
.slide-chapter-chapter {
  letter-spacing: 0.07em;
  font-size: 2em;
  top: 2.3em;
  left: -0.6em;
}
.slide-chapter-no {
  font-size: 14em;
  bottom: -0.03em;
  line-height: 0.75em;
  left: -0.1714em;
  letter-spacing: -0.1em;
}
.ios .slide-chapter-no {
  bottom: 0.06em;
}
[data-slide="4"] .slide-chapter-no {
  letter-spacing: -0.03em;
}
.slide-intro {
  width: 100%;
  position: relative;
  margin-top: 2.8em;
  margin-bottom: 2.6em;
}

.slide-intro-p {
  position: relative;
}

.slide-intro p {
  margin: -0.438em 0 -0.188em 0;
  padding-left: 1.438rem;
  max-width: 35.294em;
  position: relative;
}
.slide-left-spacer,
.slide-chapter {
  width: 19.5%;
}
.slide-button {
  margin-top: 0;
  margin-bottom: 3.153em;
  padding-left: 0;
  width: 100%;
}
.slide-button .btn {
  margin-left: -4px;
}
.slide-navigation {
  position: absolute;
  z-index: 1;
  left: 2vw;
  bottom: 4.2vh;
  font-size: 0.9rem;
}
.slide-navigation span:last-child {
  display: block;
  position: relative;
  width: 20em;
  font-family: "din";
  text-transform: uppercase;
  line-height: 1;
  font-size: 1.28em;
}
.slide-next {
  margin: 0 0 1.2vh 1.8vw;
}
.slide-navigation a {
  padding: 0.54vh 0.625em;
  color: #222;
  transition-property: margin, padding;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}
.desktop .slide-navigation a.slide-next:hover {
  padding-left: 1.375em;
}
.desktop .slide-navigation a.slide-prev:hover {
  margin-left: 0.813em;
  padding-right: 1.375em;
}
.desktop .slide-navigation a:hover i {
  color: var(--blue);
}
.slide-navigation i {
  font-size: 1.688em;
  height: 0.889em;
  margin: 0 0.444em;
  width: 1.185em;
  text-align: center;
  transition: 0.25s ease-out;
  display: flex;
}
.slide-scroll-indicator {
  font-size: var(--button-rem);
  width: 2.375em;
  height: 5em;
  background: var(--blue);
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}
.slide-image-container {
  overflow: visible;
}
.slide-image {
  position: absolute;
  bottom: -3%;
  right: 10%;
  display: flex;
  height: 100%;
  justify-content: flex-end;
}
.slide-image img {
  display: block;
  align-self: flex-start;
  justify-self: flex-end;
  position: relative;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) -4em 3em 2.5em);
}

.images3 .slide-image img {
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 1.8em 0.7em 2.5rem);
}

.images2 .slide-image img {
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0.1em 2.7em 2em);
}

.images1 .slide-image {
  bottom: -2%;
  right: -2%;
}
.images1 .slide-image img {
  max-width: 55vw;
  align-self: flex-end;
  max-height: 80%;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 1em 3em 2.5em);
}
.images2 .slide-image {
  bottom: -1%;
  right: 15%;
}
.images2 .slide-image img {
  max-width: 28vw;
  max-height: 90%;
  align-self: flex-end;
}
.images3 .slide-image {
  top: 6%;
  right: 20%;
}
.images3 .slide-image img {
  max-height: 80%;
  max-width: 24vw;
}
.images4 .slide-image {
  top: 15%;
  right: 5.5%;
}

.images4 .slide-image img {
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0.1em 2.7em 2em);
  max-width: 33vw;
  max-height: 65%;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
  .images1 .slide-image {
    right: -5%;
  }
  .images2 .slide-image {
    right: 11%;
  }
  .images3 .slide-image {
    right: 15%;
  }
  .images4 .slide-image {
    right: 4%;
  }
  .slide-background-mask .mask-left {
    width: 75%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.95) 60%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .portrait .slide-intro p {
    max-width: 23.5em;
  }
  .portrait .images1 .slide-image {
    right: -16%;
  }
  .portrait .images1 .slide-image img {
    max-width: 83vw;
  }
  .portrait .images2 .slide-image {
    right: -3%;
  }
  .portrait .images2 .slide-image img {
    max-width: 42vw;
  }
  .portrait .images4 .slide-image {
    top: 10.5%;
  }
  .portrait .images4 .slide-image img {
    max-width: 40vw;
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0.1em 2.7em 2em);
  }
  .portrait .images3 .slide-image {
    right: 10%;
    top: 5%;
  }
  .portrait .images3 .slide-image img {
    max-width: 30vw;
  }
}
@media screen and (max-width: 1440px) {
  .slide-header {
    font-size: 0.8rem;
  }
  .chapter-slide-content {
    font-size: 0.85rem;
  }
  .slide-background-mask .mask-left {
    width: 80%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.95) 60%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
@media screen and (max-width: 1200px) {
  .slide-header {
    font-size: 0.75rem;
  }
  .lead {
    line-height: 2;
  }
  .slide-background-mask .mask-left {
    width: 80%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.95) 40%,
      rgba(255, 255, 255, 0.8) 60%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .images1 .slide-image {
    right: -8%;
  }
  .images1 .slide-image img {
    max-width: 60vw;
  }
  .images2 .slide-image {
    right: 5%;
  }
  .images2 .slide-image img {
    max-width: 34vw;
  }
  .images3 .slide-image {
    right: 11%;
  }
  .images4 .slide-image {
    top: 13%;
    right: 3%;
  }
  .images4 .slide-image img {
    max-width: 31vw;
  }
}
@media screen and (max-width: 1024px) {
  .slide-header {
    font-size: 0.65rem;
    margin-top: 0;
  }
  .chapter-slide-content {
    font-size: 0.8rem;
  }
  .slide-intro p {
    max-width: 27em;
  }
}
@media screen and (max-width: 991px) {
  .slide-header {
    font-size: 0.6rem;
    margin-top: 0;
  }
  .chapter-slide-content {
    font-size: 0.5rem;
  }
  .lead {
    line-height: 1.8;
  }
  .slide-intro p {
    padding-right: 1.5em;
    max-width: 29em;
  }
  .landscape .chapter-slide-content {
    padding-top: calc(3.5 * var(--button-rem));
  }
  .landscape .slide-navigation {
    flex-direction: row;
    bottom: 1.4rem;
    left: 1em;
  }
  .landscape .slide-navigation span:last-child {
    display: none;
  }
  .landscape .slide-prev {
    order: 1;
    margin: 0;
  }
  .landscape .slide-next {
    order: 2;
    margin: 0 0 0.016em;
  }
  .landscape .slide-next .italic {
    order: 2;
  }
  .landscape .slide-next i {
    order: 1;
    margin: 0 0.21em 0 0;
  }
  .landscape .slide-prev i {
    margin: 0 0 0 0.25em;
  }
  .landscape .slide-header {
    font-size: 0.35rem;
  }
  .landscape .slide-button .btn-primary {
    font-size: 16px;
    padding-left: 0.2em;
  }
  .landscape .lead {
    font-size: 12px;
    line-height: 1.7;
    padding-left: 1rem;
  }
  .landscape .chapter-slide-content {
    font-size: 0.4rem;
  }
  .landscape .slide-chapter-chapter {
    display: none;
  }
  .landscape .slide-chapter-no {
    font-size: 17em;
    bottom: -0.016em;
  }
  .landscape.ios .slide-chapter-no {
    bottom: 0.06em;
  }
  .landscape .slide-title h1 {
    padding-left: 0.9rem;
  }
  .landscape .slide-left-spacer,
  .landscape .slide-chapter {
    width: 18%;
  }
}
@media screen and (max-width: 767px) {
  .slide-background-mask .mask-left {
    width: 90%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.95) 30%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .landscape .slide-header {
    font-size: 0.3rem;
  }
  .slide-chapter-chapter {
    display: none;
  }
  .slide-chapter-chapter,
  .slide-chapter-no {
    color: white;
  }
  .slide-chapter .slide-chapter-box,
  .slide-chapter .slide-chapter-box::before {
    background: var(--grey);
  }
  .portrait .slide-chapter-no {
    font-size: 14em;
    bottom: 0.06em;
    left: -0.17em;
  }
  .portrait .slide-header {
    font-size: 0.4rem;
  }
  .portrait .chapter-slide-content {
    font-size: 8px;
  }
  .portrait .slide-intro p {
    padding-left: 1rem;
  }
  .portrait .slide-title h1 {
    padding-left: 0.9rem;
  }
  .portrait .slide-button .btn-primary {
    padding-left: 0.2em;
  }
  .portrait .slide-navigation {
    flex-direction: row;
    bottom: 1.4rem;
    left: 1em;
  }
  .portrait .slide-navigation span:last-child {
    display: none;
  }
  .portrait .slide-prev {
    order: 1;
    margin: 0;
  }
  .portrait .slide-next {
    order: 2;
    margin: 0 0 0.016em;
  }
  .portrait .slide-next .italic {
    order: 2;
  }
  .portrait .slide-next i {
    order: 1;
    margin: 0 0.21em 0 0;
  }
  .portrait .slide-prev i {
    margin: 0 0 0 0.25em;
  }
  .portrait .slide-button .btn-primary {
    font-size: 18px;
  }
}
@media screen and (max-width: 600px) {
  .landscape .slide-left-spacer,
  .landscape .slide-chapter {
    width: 14%;
  }
}
@media screen and (max-width: 479px) {
  .portrait .slide-background-mask .mask-left {
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.95) 20%,
      rgba(255, 255, 255, 0.7) 65%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .portrait .slide-image {
    font-size: 10px;
  }
  .portrait .slide-left-spacer,
  .portrait .slide-chapter {
    width: 17%;
  }
  .portrait .slide-header {
    font-size: 0.36rem;
  }
  .portrait .chapter-slide-content {
    font-size: 6px;
  }
  .portrait .slide-button .btn-primary {
    font-size: 17px;
  }
  .portrait .lead {
    font-size: 12px;
    line-height: 1.9;
  }
  .portrait .images1 .slide-image {
    right: -18%;
  }
  .portrait .images1 .slide-image img {
    max-width: 81vw;
  }
  .portrait .images2 .slide-image {
    right: -6%;
  }
  .portrait .images2 .slide-image img {
    max-width: 40vw;
  }
  .portrait .images4 .slide-image {
    top: 9%;
  }
  .portrait .images4 .slide-image img {
    max-width: 39vw;
  }
  .portrait .images3 .slide-image {
    right: 10%;
    top: 5%;
  }
  .portrait .images3 .slide-image img {
    max-width: 30vw;
  }
}
@media screen and (max-width: 420px) {
  .portrait .slide-chapter-no {
    font-size: 12em;
    bottom: 0.14em;
  }
}
@media screen and (max-width: 400px) {
  .portrait .slide-button .btn-primary {
    font-size: 16px;
    margin-bottom: 0.8rem;
  }
  .portrait .images1 .slide-image {
    right: -22%;
  }
  .portrait .images2 .slide-image {
    right: -7%;
  }
  .portrait .images4 .slide-image {
    top: 8%;
    right: 6%;
  }
  .portrait .images4 .slide-image img {
    max-width: 37vw;
  }
}
@media screen and (max-height: 400px) {
  .landscape .slide-navigation {
    display: none;
  }
  .landscape .lead {
    font-size: 11px;
  }
  .landscape .chapter-slide-content {
    font-size: 5px;
  }
  .landscape .slide-button .btn-primary {
    font-size: 14px;
  }
}
@media screen and (max-width: 390px) {
  .portrait .slide-chapter-no {
    font-size: 11em;
    bottom: 0.2em;
  }
}
</style>
